// import config from '@/config';
import { locale } from '@/utils/locale';
// import request from '@/utils/request';
import bridgeTrack from 'bridgetrack';
import { setLocale } from 'umi';
import appInfo from '@/stores/appInfo';
import bridge from '@/utils/bridge';
import vl from '@/utils/vl';
import '@credit/tips/lib/index.css';
import { isCallByDrawer, RNTracker, hyperLinkInterceptor } from './utils/common';

// 设置html fontSize等，H5 rem
vl(50, 375);

// 设置app info
appInfo.setAppInfo();
appInfo.setExtraInfo();

bridge.call('configurePage', {
  config: {
    disableReload: 1,
    disableBounce: 1,
  },
});

bridgeTrack
  .config({ bridge: isCallByDrawer() ? RNTracker : bridge, debug: REACT_APP_ENV !== 'live' })
  .autoExpo();
// 无离线包
// bridgeTrack.sdkTrackEvent('action_landing_success', {
//   page_type: 'cashloan_activate_info',
//   data: {
//     is_offline: window._offline_build_url_query ? 1 : 0,
//   },
// });

// 设置多语言信息
setLocale(locale);
