export enum BIZ_TYPE {
  None = 0,
  Buyer = 1,
  Seller = 2,
  ShopeePayLater = 3,
  FastEscrow = 4,
}

// 特殊的rn版本记录
export enum APP_VERSION {
  ANDROID_LC = 25130,
  IOS_LC = 25112,
}

// 特殊的app版本记录
export enum RN_VERSION {
  LC_AVAILd = 1583408502,
}

// 枚举步骤条状态
export enum StepsEnum {
  wait = 1,
  active,
  finish,
}

// 枚举步骤条文本状态
export enum StepsTitleEnum {
  active_desc = 0,
  wait = 1,
  active,
  finish,
}

// 用户的shopeepay状态
export enum USER_WALLET_STATUS {
  OK = 0,
  BANNED = 1,
  FROZEN = 2,
  DISABLED = 4,
}

/** 跨产品激活检测错误 - 状态
 * - None
 * - Existing 用同一shopeeId激活
 * - Frozen 其他产品已冻结或逾期
 * - Review 其他产品在激活中
 */
export enum CrossProduct {
  None = 0,
  Existing,
  Frozen,
  Review,
}
