import request from '@/utils/request';
import { WithPagination } from '@/types/index'; /* eslint-disable-line */

// interface getVoucherListParams extends WithPagination {
//   voucherStatus: VOUCHER_STATUS
// }

// 新版登录，不需要这个接口
export const login = (params: { auth_code: string }) => {
  return request('/h5/user_login', {
    method: 'POST',
    body: params,
  });
};

export const getProductList = (params?: { offset: number; limit: number }) => {
  return request('/h5/query_products', {
    method: 'POST',
    body: params,
  });
};

export const getProductDetail = (params: { insurance_product_id: string }) => {
  return request('/h5/get_product_detail', {
    method: 'POST',
    body: params,
  });
};

// 保单列表
export const getPolicyList = (params?: { offset: number; limit: number }) => {
  return request('/h5/query_policies', {
    method: 'POST',
    body: params,
  });
};

// 保单详情
export const getPolicyDetail = (params: { shopee_policy_no: string }) => {
  return request('/h5/get_policy_detail', {
    method: 'POST',
    body: params,
  });
};

// 保单详情列表页（standalone新增)
export const getPolicyDetailList = (params?: {
  purchase_id: string;
  account_id: string;
  purchase_item_id: string;
}) => {
  return request('/h5/get_policy_detail_list', {
    method: 'POST',
    body: params,
  });
};
