import { GeneralAction as Actions, IGeneral, IModelType } from '@/types';
import {
  closeWebview,
  getCurPathInfo,
  hideAllFullLoading,
  isSamePath,
  setFullLoadingOnly,
} from '@/utils/common';

let timer: any = void 0;

const Model: IModelType<IGeneral, Actions> = {
  namespace: 'general',
  state: {
    curPathName: '',
    lastPathName: '',
    curPageType: '',
    lastPageType: '',
    pathQueue: [],
  },
  reducers: {
    [Actions.save](state, { payload }) {
      return { ...state, ...payload };
    },
  },
  effects: {
    *[Actions.onRouteChange]({ payload }, { put, select }) {
      const gState: IGeneral = yield select((store) => store.general);
      const { location } = payload || {};
      const { pathname: curPathName } = location || {};
      const { curPathName: lastPathName, curPageType: lastPageType, pathQueue } = gState;

      if (isSamePath(lastPathName, curPathName)) {
        return;
      }

      const obj: any = getCurPathInfo(curPathName);

      if (obj && obj.showLoading) {
        setFullLoadingOnly();
      } else {
        hideAllFullLoading();
      }

      yield put({
        type: 'save',
        payload: {
          curPathName,
          lastPathName,
          curPageType: obj?.pageType || '',
          lastPageType,
          pathQueue: [...pathQueue, curPathName],
        },
      });
    },
    *[Actions.onBacking]() {
      if (timer) {
        clearTimeout(timer);
      }
      const lastHref = window.location.href;
      timer = setTimeout(() => {
        if (lastHref === window.location.href) {
          closeWebview();
        }
      }, 300);
    },
  },
  subscriptions: {
    init({ history, dispatch }) {
      return history.listen((location) => {
        dispatch({
          type: Actions.onRouteChange,
          payload: {
            location,
          },
        });
      });
    },
  },
};

export default Model;
