import bridge from '@/utils/bridge';
import { isCallByDrawer, queryParams } from '@/utils/common';

class AppInfo {
  public from: string;
  public authCode: string;
  public deviceID: string;
  public deviceFingerprint: string;
  public brand: string | number;
  public model: string | number;
  public networkType: string | number;
  public cellularType: string | number;
  public pageIdMap: { [prop: string]: string };
  public loadingList: string[] = [];
  public firstPathname: string = '';
  public gpsloc: any;

  constructor() {
    this.from = '';
    this.authCode = '';
    this.deviceID = '';
    this.deviceFingerprint = '';
    this.brand = '';
    this.model = '';
    this.networkType = 0;
    this.cellularType = '';
    this.pageIdMap = {};
    this.gpsloc = undefined;
  }

  // 设置设备信息
  public async setDeviceInfo() {
    if (isCallByDrawer()) return;
    const res: any = await bridge.getAppInfo();
    const { deviceID, deviceFingerprint } = res || {};
    this.deviceID = deviceID || '';
    this.deviceFingerprint = deviceFingerprint || '';
    this.firstPathname = window.location.pathname;
  }

  public async setExtraInfo() {
    if (isCallByDrawer()) return;
    const [res1, res2]: any[] = await Promise.all([
      bridge.getDeviceInfo(),
      bridge.getNetworkInfo(),
    ]);
    const { brand, model } = res1 || {};
    const { networkType, cellularType } = res2 || {};

    this.brand = brand || '';
    this.model = model || '';
    this.networkType = networkType || 0;
    this.cellularType = cellularType || '';

    const [hasLocPermission] = await bridge.checkPermission(['location']);
    if (hasLocPermission) {
      const gpsloc = await bridge.getLocation(true);
      this.gpsloc = gpsloc;
      // console.log('gpsloc init: ', gpsloc, '; at ', new Date().toString());
    }
  }

  // 初始化时设置公共model
  public setAppInfo() {
    const { from = '' } = queryParams();

    this.from = from || 'others';
    this.setDeviceInfo();
  }
}

export default new AppInfo();
