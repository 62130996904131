import { history } from 'umi';
// import { ILoginRes, IR } from '@credit/itypes/lib/types';
import { login } from '@/services/api';
import { IUserInfo } from '@/types';
import { getAuthCode, goWsaUrl, toErrPage, toHomePage } from '@/utils/common';
import bridge from '@/utils/bridge';
import { LoginToken } from '@/types/constant';
// @ts-ignore
import Cookies from 'js-cookie'

const UNACTIVE = [30001, 30002, 30003, 90113, 90011]; // 黑名单用户错误码

export default {
  namespace: 'userInfo',
  state: {
    islogin: false,
  } as IUserInfo,

  reducers: {
    saveUserInfo(state: any, { payload }: any) {
      return { ...state, ...payload };
    },
  },
  effects: {
    *requestUserInfo(_: any, { put }: any) {
      const authCodeResp = yield getAuthCode();
      const [errcode, authCode] = authCodeResp;
      if (!authCode) {
        // errcode=0，表示是RN没取到，此时要跳转wsa url兜底；否则表示wsa也没取到，展示错误页就行
        errcode === 0 ? goWsaUrl() : history.push({ pathname: '/exception' });
        return false;
      }

      const { code: lcode, data: lresult } = yield login({
        auth_code: authCode,
      });
      // console.log("lresult is", lresult);
      // console.log(REACT_APP_ENV);
      if (lcode === 0) {
        if (REACT_APP_ENV === 'dev') {
          // dev模式，手机连本地调试，域名是localhost，要setCookie到本地域名，否则后续请求带不上cookie。login是set到shopeekredit.co.id下
          document.cookie = `itoken=${lresult.token}`; // insurance接口字段是token
        }
        yield put({
          type: 'saveUserInfo',
          payload: {
            islogin: true,
          },
        });
        return true;
      }
      bridge.trackPerformanceEvent('getAuthCodeFail', {
        fail_type: 2,
        err_code: lcode,
      });
      toErrPage();
      return false;
    },
    *callLogin(_: any, { put }: any) {
      const shopeeToken = Cookies.get('shopee_token')
      // 如果shopeeToken不存在才跳转到
      if (!shopeeToken) {
        goWsaUrl()
      } else {
        history.push({ pathname: '/exception' });
      }
    }
  },
};
