import { ENV, MdapSdk } from '@mdap/javascript-sdk';
import APIPlugin from '@mdap/sdk-plugin-api';
import PagePerfPlugin from '@mdap/sdk-plugin-performance';
import ResourcePlugin from '@mdap/sdk-plugin-resource';
import exceptionPlugin from './utils/exceptionHandler';

const MDAPSecretKeys: { [key: string]: string } = {
  // mock: '1b939648debe590cd4964c50c93c1bfb7c634f1cdb445a40f0e8f158811fe619',
  test: '1b939648debe590cd4964c50c93c1bfb7c634f1cdb445a40f0e8f158811fe619',
  live: '08eb41cff7a4d71307b5dad9f95c92866c1b717352b3dfece7adc1eece2f4993',
};

const env = REACT_APP_ENV === 'live' || REACT_APP_ENV === 'uat' ? 'live' : 'test';

const reportEnvMap = {
  // mock: 'test',
  live: 'live',
  test: 'test',
  test1: 'test',
  uat: 'live',
  staging: 'staging',
};

console.log('===== PRESETTING', REACT_APP_ENV, Object.keys(reportEnvMap).includes(REACT_APP_ENV));
// 只有test, uat, live环境才接入MDAP
if (MDAPSecretKeys[env] && Object.keys(reportEnvMap).includes(REACT_APP_ENV)) {
  // 初始化SDK SDK initialization
  const sdk = new MdapSdk({
    // mdap 应用id application id
    app_name: 'shopee-insurance-h5',
    // mdap 应用对应环境密钥, live环境为live, 其他环境为test
    secret_key: MDAPSecretKeys[env],
    // reporting env: test | staging | uat | live
    // if environment is ‘live’ ，data will be reported to Live env for user: https://mdap.shopee.io; else, data will be reported to Test env for user:  https://mdap.exp.shopee.io
    // 上报环境: test | staging | uat | live
    // environment值为 ‘live’ 时，数据会上报到正服环境 https://mdap.shopee.io， 为其他值时数据会上报到测服  https://mdap.exp.shopee.io
    environment: reportEnvMap[REACT_APP_ENV as keyof typeof reportEnvMap] as ENV,
    // 上报地区/Reporting Region | sg | tw | ph | th | id | my | br | mx | ...
    region: BIZ_COUNTRY,
    // 是否开启调试日志/Toggle of debug logger | default - false
    logger: env === 'test',
    // 业务应用版本 business application version
    // eslint-disable-next-line @typescript-eslint/naming-convention
    app_version: RELEASE_TAG,
    // sampling rate 采样率 - default 0.1 | 采样结果会在下一次启动时(刷新或新开tab)生效 Sampling result will be effective at next startup (refresh page OR open a new tab)
    sample: 1,
  });

  const apiPlugin = new APIPlugin({
    path: '/api',
  });
  // 静态资源加载监控插件 Plugin of resource loading
  const resourcePlugin = new ResourcePlugin({
    path: ['/insurance-config'],
  });

  // 页面性能监控插件 Plugin of page performance
  const pagePerfPlugin = new PagePerfPlugin({
    path: ['/'],
  });

  sdk.use(apiPlugin);
  sdk.use(resourcePlugin);
  sdk.use(pagePerfPlugin);
  sdk.use(exceptionPlugin);
}

export const dva = {
  config: {
    onError(err: ErrorEvent) {
      err.preventDefault();
      console.error(err.message);
    },
  },
};
