import { Toast } from '@credit/tips';
import { Request, typeCheck as tc, IRequestConfig } from '@credit/utils';
import { history, getDvaApp } from 'umi';
import { RequestOptionsInit, ResponseError } from 'umi-request';
// import { BizCommon } from '@/types/constant';
import appInfo from '@/stores/appInfo';
import config from '@/config';
import { v4 as uuidv4 } from 'uuid';
import { formatMessage, locale } from './locale';
import hmacSHA256 from 'crypto-js/hmac-sha256';
import Base64 from 'crypto-js/enc-base64';
import { appDispatch } from './common';
import { captureError } from './exceptionHandler';

interface IOptions extends RequestOptionsInit {
  method: 'POST' | 'GET' | 'PUT' | 'DELETE';
  body?: any;
  query?: any;
  headers?: any;
  bodyHeader?: any;
  noBodyHeader?: boolean;
  formatOptions?: (options: IOptions, needRisk: boolean, context: Request) => any;
  isInsight?: boolean;
  needDebounce?: boolean;
  umiReqOpts?: RequestOptionsInit;
  manual?: boolean;
}

const { secretKey, publicKey, appId, version, platForm, region, appVersion, rnVersion } = config;

const isUplaodUserUrl = (url: string = '') => {
  return tc.isString(url) && url.indexOf('/upload/user') >= 0;
};

export const getSignature = (obj: {
  url: string;
  method: string;
  params: any;
  timestamp: number;
  uuid: string;
  config: IRequestConfig;
}) => {
  const { secretKey } = config;
  const { url, method, timestamp, uuid, params } = obj;

  const str = [method ?? '', url ?? '', timestamp ?? '', uuid ?? '', JSON.stringify(params ?? '')]
    .map((it) => `${it}\n`)
    .join('');

  return Base64.stringify(hmacSHA256(str, secretKey));
};

const insuranceFormatOptions = (
  subUrl: string,
  options: IOptions,
  needRisk: boolean,
  context: Request,
) => {
  const { method, body = {}, bodyHeader = {}, headers = {}, umiReqOpts = {}, query } = options;
  const newbody = body ?? {};
  const timestamp = Date.now();
  const uuid = uuidv4();
  const newHeader = {
    'X-Req-AppId': appId,
    'X-Req-Signature': getSignature({
      url: `/api${subUrl}`,
      method,
      params: newbody,
      timestamp,
      uuid,
      config: context.config,
    }),
    'X-Req-Nonce': uuid,
    'X-Req-TraceId': uuid,
    'X-Req-Timestamp': timestamp,
    'X-Req-Lang': BIZ_COUNTRY === 'my' && locale === 'zh' ? 'en' : locale,
    ...headers,
  };

  return {
    method: method.toLocaleLowerCase(),
    data: newbody,
    params: query,
    headers: newHeader,
    ...umiReqOpts,
  };
};

const checkSpecCode = (response: any, options: any, url: string = '') => {
  const code = response?.code;
  if (url.lastIndexOf('report_statistics') > -1) {
    return;
  }
  // const gState = getAppState('general')
  switch (true) {
    case code === 10104001:
      appDispatch({ type: 'userInfo/requestUserInfo' });
      break;
    case code === 90006:
      appDispatch({ type: 'userInfo/callLogin' });
      break;
    case code <= 90001 && code >= 90016:
      history.replace('/exception');
      break;
    case code !== 0 && !options?.manual:
      Toast.fail(response?.msg ?? formatMessage({ id: 'network_error' }));
      break;
    default:
      break;
  }
};

const dupCheckUrls = [
  '/cashloan/account/cl_apply_activation_submit',
  '/cashloan/transaction/cl_apply_loan_pre_submit',
  '/cashloan/transaction/cl_apply_loan_do_submit',
  '/cashloan/transaction/cl_trigger_repayment',
];

const request = new Request({
  secretKey,
  publicKey,
  appId,
  version,
  platForm,
  region,
  appVersion,
  rnVersion,
  // bizCommon: BizCommon,
  appInfo,
  beforeAll: (url, options) => {
    let nextUrl = url;
    const nextOptions = options;
    if (isUplaodUserUrl(url)) {
      nextOptions.noBodyHeader = true;
    } else {
      nextUrl = `/api${url}`;
    }
    return {
      url: nextUrl,
      options: nextOptions,
    };
  },
  afterAll: (data) => {
    const newData: any = tc.isObject(data)
      ? data
      : {
          code: -1,
          msg: 'resp null',
          result: {},
        };
    if (
      newData.result &&
      tc.isObject(newData.result.common_result) &&
      newData.code === 0 &&
      newData.result.common_result.err_code !== 0
    ) {
      newData.code = newData.result.common_result.err_code;
      newData.msg = newData.result.common_result.err_msg;
    }
    return newData;
  },
});

request.requestUse((url, options) => {
  const { errorHandler, headers = {} as any } = options;
  const traceId = headers['X-Req-TraceId'];
  const requestTime = Date.now();
  const newErrorHandler = (e: ResponseError) => {
    const { type, response = {} as Response } = e;
    switch (type) {
      case 'HttpError':
        if (response.status && response.status >= 500 && response.status <= 504) {
          // history.push({ pathname: '/exception' });
        } else if (response.status && response.status >= 403 && response.status < 422) {
          captureError({
            message: 'API_ERR_CODE',
            errorInfo: {
              data_field: {
                traceId,
              },
              extra: {
                url,
                traceId,
                user: getDvaApp()._store.getState()?.userInfo ?? {},
                body: {
                  code: response.status,
                  message: e,
                },
              },
            },
          });
        }
        break;
      case 'Timeout':
        Toast.fail(formatMessage({ id: 'network_error' }));
        captureError({
          message: 'API_TIME_OUT',
          errorInfo: {
            data_field: {
              traceId,
            },
            extra: {
              url,
              traceId,
              user: getDvaApp()._store.getState()?.userInfo ?? {},
              body: {
                duration: Date.now() - requestTime,
                startTime: new Date(requestTime).toLocaleString(),
                message: e,
              },
            },
          },
        });
        break;
      case 'redirect': // 已重定向，不需要处理
        break;
      case 'ParseError':
        captureError({
          message: 'API_RESPONSE_PARSE_ERROR',
          errorInfo: {
            data_field: {
              traceId,
            },
            extra: {
              url,
              traceId,
              user: getDvaApp()._store.getState()?.userInfo ?? {},
              data: {
                response,
                message: e,
              },
            },
          },
        });
        break;
      default:
        Toast.fail(formatMessage({ id: 'network_error' }));
        captureError({
          message: 'API_UNCAUGHT_ISSUES',
          errorInfo: {
            data_field: {
              traceId,
            },
            extra: {
              url,
              traceId,
              user: getDvaApp()._store.getState()?.userInfo ?? {},
              data: {
                message: e,
              },
            },
          },
        });
        break;
    }
    if (errorHandler) {
      return errorHandler(e);
    }
    return {
      code: 'request error',
      traceId,
      msg: '',
      errDeal: true,
      result: {},
    };
  };
  options.errorHandler = newErrorHandler;
  options.throwErrIfParseFail = true;
  return {
    url,
    options,
  };
});

request.responseUse(async (response, options) => {
  const status = response?.status ?? 0;
  if (!response || parseInt(`${status}`, 10) >= 400) {
    const err = { response };
    return Promise.reject(err);
  }
  const data = await response.clone().json();
  // data = mixCode(data)
  checkSpecCode(data, options, response?.url ?? '');
  // return switchKeys(data, toCamelCase)
  return data;
});

export default (subUrl: string, options: IOptions, needRisk: boolean = true): Promise<any> => {
  options.formatOptions = insuranceFormatOptions.bind(this, subUrl);
  dupCheckUrls.some((it) => subUrl.indexOf(it) >= 0) && (options.needDebounce = true);
  return request.fetch(subUrl, options, needRisk);
};
