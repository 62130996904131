import { Dispatch, IAnyAction } from '@/types';
import { connect } from 'umi';

export const createGlobalAction = (action: string, model: string, payload?: any) => {
  return {
    type: `${model}/${action}`,
    payload,
  };
};

export const createDispatchProp = (
  dispatch: Dispatch<IAnyAction>,
  action: string,
  model: string,
) => {
  return (payload?: any) => dispatch(createGlobalAction(action, model, payload));
};

export const getConnectModel = (actionsMap: any) => {
  const getKeys = (model: string) => Object.keys(actionsMap[model] || {});
  return (
    modelNames?: string | string[],
    opts?: {
      noAutoDispatch?: boolean;
      mapStateToProps: (state: any, props: any) => any;
      mapDispatchToProps: (
        dispatch: any,
        props: any,
      ) => {
        [p: string]: (...args: any[]) => any;
      };
    },
  ) => {
    return connect(
      (state: any, props: any) => {
        if (!modelNames) {
          return {};
        }
        let propState: any = {};
        const nameList = Array.isArray(modelNames) ? modelNames : [modelNames];
        for (const name of nameList) {
          propState[name] = state[name];
        }
        if (opts?.mapStateToProps) {
          const mapTProps = opts?.mapStateToProps(state, props);
          propState = { ...propState, ...mapTProps };
        }
        return propState;
      },
      (dispatch, props) => {
        if (opts?.noAutoDispatch || !modelNames) {
          return { dispatch };
        }
        const name = Array.isArray(modelNames) ? modelNames[0] : modelNames;
        const actionKeys = getKeys(name);
        let modelProps: any = {};
        for (const action of actionKeys) {
          modelProps[action] = createDispatchProp(dispatch, action, name);
        }
        if (opts?.mapDispatchToProps) {
          const mapTProps = opts?.mapDispatchToProps(dispatch, props);
          modelProps = { ...modelProps, ...mapTProps };
        } else {
          modelProps.dispatch = dispatch;
        }
        return modelProps;
      },
    );
  };
};
