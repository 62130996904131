import Bridge from '@credit/ibridge';

const bridge = new Bridge();

bridge.setConfig({
  defaultTitle: APP_TITLE,
  stopTrack: BIZ_COUNTRY === 'th',
});

export default bridge;
