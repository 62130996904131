import { ListView } from 'antd-mobile';
import { IModelType } from '@/types';
import { policyNS, IPolicy, PolicyAction as Actions } from '@/pages/Policy/model.i';
import { getPolicyList } from '@/services/api';

const ds = new ListView.DataSource({
  rowHasChanged: (r1: any, r2: any) => r1.voucherId !== r2.voucherId,
});

const Model: IModelType<IPolicy, Actions> = {
  namespace: policyNS,
  state: {
    list: [],
    dataSource: ds.cloneWithRows([]),
    pageIndex: 0,
    pageSize: 10,
    hasMore: true,
    total: 0,
  },
  reducers: {
    // 更新状态
    [Actions.updateList](state, { payload }) {
      const { list: newList, has_more, pageIndex } = payload;
      const list = [...state.list, ...newList];
      const dataSource = ds.cloneWithRows(list);

      return { ...state, list, dataSource, hasMore: has_more, pageIndex };
    },

    // 初始化状态
    [Actions.clearList]() {
      return {
        list: [],
        dataSource: ds.cloneWithRows([]),
        pageIndex: 0,
        pageSize: 10,
        hasMore: true,
        total: 0,
      };
    },

    // 更新详细
    [Actions.updateInfo](state, { payload }) {
      return { ...state, info: payload };
    },
  },
  effects: {
    *[Actions.fetchList](_, { put, call, select }) {
      const state: IPolicy = yield select((store) => store[policyNS]);
      // 如果上一次告诉已经无数据，不需要再请求了
      if (!state.hasMore) return false;

      const result = yield call(getPolicyList, {
        offset: state.pageIndex + 1,
        limit: state.pageSize,
      });
      const { code, data } = result;
      if (code !== 0) return false;

      yield put({
        type: Actions.updateList,
        payload: {
          list: data.policies,
          has_more: data.has_more,
          pageIndex: state.pageIndex + 1,
        },
      });
      return true;
    },
  },
};

export default Model;
