import { EnKeyof } from '@/locales/en';
import { getFormatObjKeyMsg, getObservableMsg, isPC } from '@credit/utils';
import { Region } from '@credit/utils/lib/types/types';
import { isInShopeeApp } from '@/utils/common';
import { NationEnum } from 'config/siteConfig';
import { createIntl, createIntlCache } from 'react-intl';
// 就是从 useragent 获取当前语言的值

interface Langs {
  supportLangs?: string[];
  defaultLang?: string;
}

export const ShopeeSupportLangsMap: Record<Region, Langs> = {
  id: {
    supportLangs: ['en', 'id'],
    defaultLang: 'en',
  },
  my: {
    supportLangs: ['en', 'my', 'zh'],
    defaultLang: 'en',
  },
  vn: {
    supportLangs: ['vi'],
    defaultLang: 'vi',
  },
  th: {
    supportLangs: ['th', 'en'],
    defaultLang: 'en',
  },
  ph: {
    supportLangs: ['en', 'zh'],
    defaultLang: 'en',
  },
  sg: {},
  tw: {},
  br: {},
  in: {},
  zh: {},
};

const getLang = () => {
  const ua = navigator.userAgent;
  const langs = [
    'en',
    'sg',
    'vi',
    'id',
    'ms',
    'my',
    'th',
    'zhHans',
    'zhHant',
    'zh-Hans',
    'zh-Hant',
    'fa',
    'ms_my',
    'ms-my',
    'zh-CN',
    'th-TH',
    'vi-VN',
    'ms-MY',
    'id-ID',
    'zh',
    'zh-TW',
  ];
  // 为了适配RW端，需要增加很多语言的缩写

  const arr = ua.split(' ');
  let locale = '';
  let tmp = '';

  let isInShopeeApp = false;
  (function (useragent) {
    if (/Shopee/i.test(useragent)) {
      isInShopeeApp = true;
    }
  })(navigator.userAgent || navigator.vendor || window.opera);

  console.log('navigator?.language value is:', navigator?.language);

  arr.forEach((it) => {
    if (it.startsWith('locale/')) {
      // IsFuckingAndroid
      tmp = it.slice(it.indexOf('/') + 1);
    } else if (it.startsWith('locale=')) {
      // IsFuckingIOS
      tmp = it.slice(it.indexOf('=') + 1);
    }

    locale = langs.includes(tmp) ? tmp : '';
  });

  // 非shopee app 内部
  if (!locale && !isInShopeeApp) {
    const lang = navigator?.language;
    if (langs.includes(lang)) return lang;
    switch (true) {
      case lang.toLowerCase().indexOf('my') !== -1:
        locale = 'my';
        break;
      case lang.toLowerCase().indexOf('id') !== -1:
        locale = 'id';
        break;
      case lang.toLowerCase().indexOf('zh') !== -1:
        locale = 'zh';
        break;
      case lang.toLowerCase().indexOf('th') !== -1:
        locale = 'th';
        break;
      case lang.toLowerCase().indexOf('vi') !== -1:
        locale = 'vi';
        break;
      default:
        locale = 'en';
        break;
    }
  }

  if (BIZ_COUNTRY === 'my' && locale.includes('zh')) {
    locale = 'en';
  }
  if (BIZ_COUNTRY === 'ph' && locale.includes('zh')) {
    locale = 'en';
  }

  return locale || 'en';
};

function getLangCode(): string {
  // 就是从 useragent 获取当前语言的值
  let locale = getLang(); // 这个值必须跟src/locales下文件名相同
  const myLangCode = ['ms', 'my', 'ms-my', 'ms_my'];
  const zhLangCode = ['zhHans', 'zh-Hans', 'zh-CN', 'zh', 'zh-TW'];
  const vnLangCode = ['vn', 'vi', 'vi-VN'];
  const thLangCode = ['th', 'th-TH'];
  const idLangCode = ['id', 'id-ID'];
  switch (true) {
    case myLangCode.includes(locale) || locale.toLowerCase().indexOf('my') > -1:
      locale = 'my';
      break;
    case zhLangCode.includes(locale) || locale.toLowerCase().indexOf('zh') > -1:
      locale = 'zh';
      break;
    case vnLangCode.includes(locale) || locale.toLowerCase().indexOf('vi') > -1:
      locale = 'vi';
      break;
    case thLangCode.includes(locale) || locale.toLowerCase().indexOf('th') > -1:
      locale = 'th';
      break;
    case idLangCode.includes(locale) || locale.toLowerCase().indexOf('id') > -1:
      locale = 'id';
      break;
    // PH只有中文或者英语，因此使用默认的英语即可
    default:
      break;
  }
  if (BIZ_COUNTRY === 'my' && locale.includes('zh')) {
    locale = 'en';
  }
  if (BIZ_COUNTRY === 'ph' && locale.includes('zh')) {
    locale = 'en';
  }

  // locale = 'th';
  return locale;
}

// 给locale设置默认值
const nation = BIZ_COUNTRY || 'id';
const supportsLangs = ShopeeSupportLangsMap[nation];
const lowerCaseLocale = getLangCode().toLowerCase();
const localeOfExistingCountry = (supportsLangs?.supportLangs ?? []).includes(lowerCaseLocale)
  ? lowerCaseLocale
  : supportsLangs.defaultLang;
export const locale = localeOfExistingCountry || 'en';
// export const locale = 'th';

// create a global formatMessage outside app lifecycle
// 用于在一些 constant 文件里直接使用。（否则若从umi里直接import formatMessage 会得到undefined）
const cache = createIntlCache();
// eslint-disable-next-line import/no-dynamic-require
const messages = require(`@/locales/${locale}`).default;
const intl = createIntl(
  {
    locale,
    messages,
  },
  cache,
);

export const formatMessage = (
  info: { id: EnKeyof },
  value?: Record<string, string | number | boolean | null | undefined | Date | any>,
) => intl.formatMessage(info, value);

export const formatObservableMsg = getObservableMsg<EnKeyof>(formatMessage);
export const formatObjKeyMsg = getFormatObjKeyMsg<EnKeyof>(formatMessage);
