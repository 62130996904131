import IDCfg from './id';
import MYCfg from './my';
import VNCfg from './vn';
import PHCfg from './ph';
import THCfg from './th';

export type CfgType = typeof IDCfg;

const configMap = {
  id: IDCfg,
  my: MYCfg,
  vn: VNCfg,
  ph: PHCfg,
  th: THCfg,
};

const config = configMap[BIZ_COUNTRY as keyof typeof configMap] as CfgType;

export default config;
