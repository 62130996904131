import IDEn from './id/en.json';

export type EnKeyof = keyof typeof IDEn;
export type EnType = {
  [k in EnKeyof]: string;
};

// eslint-disable-next-line
const en: EnType = require('./' + BIZ_COUNTRY + '/en.json')
export default en;
