import { SubscriptionsMapObject } from 'dva';
import { RouteComponentProps } from 'react-router';
import { getConnectModel } from '../utils/connectModel';
import * as constant from './constant';
import * as enums from './enums';

import { policyNS, IPolicy, PolicyAction } from '@/pages/Policy/model.i';

export { enums, constant };

export interface IMoney {
  currency_code?: string;
  value: string;
}

export interface IPagination {
  pageIndex: number;
  pageSize: number;
  cursor?: number | string;
}

export interface IPageInfo {
  pageIndex: number;
  pageSize: number;
  hasMore: boolean;
  total: number;
}

export interface WithPagination {
  pagination: IPagination;
  [extraProps: string]: any;
}

// IUserInfo
export interface IUserInfo {
  islogin: boolean;
}

// IGeneral
export interface IGeneral {
  curPathName: string;
  lastPathName: string;
  curPageType: string;
  lastPageType: string;
  pathQueue: string[];
}

export enum GeneralAction {
  save = 'save',
  onRouteChange = 'onRouteChange',
  onBacking = 'onBacking',
}

/* ---------- model 类型定义 ---------- */

export interface IAnyAction {
  type: string;
  payload?: any;
  [extraProps: string]: any;
}

export interface IConnectState {
  general: IGeneral;
  userInfo: IUserInfo;
  [policyNS]: IPolicy;
}

export interface IConnectAction {
  general: GeneralAction;
  [policyNS]: PolicyAction;
}

export const connectAction = {
  // general: GeneralAction,
  // [policyNS]: PolicyAction
};

export type ISelectFn = (state: IConnectState) => any;

export type IAnyFunction = (...arg: any[]) => any;

export interface IEffectsCommandMap {
  put: <A extends IAnyAction>(action: A) => any;
  call: (fn: IAnyFunction, ...arg: any[]) => any;
  select: (fn: ISelectFn) => any;
  take: IAnyFunction;
  cancel: IAnyFunction;
  [key: string]: any;
}

export type Effect = (action: IAnyAction, effects: IEffectsCommandMap) => void;

export type Dispatch<P> = (action: IAnyAction) => P;

export interface IConnectProps<P = any, C = any, S = any> extends RouteComponentProps<P, C, S> {
  dispatch: Dispatch<Promise<any>> | Dispatch<IAnyAction>;
}

export type IModelStateProps<N extends keyof IConnectState> = Pick<IConnectState, N>;

export type IModelDispProps<N extends keyof IConnectState> = Record<
  IConnectAction[N],
  (payload?: any) => Promise<any>
>;

export type IModelProps<N extends keyof IConnectState> = IModelStateProps<N> & IModelDispProps<N>;

export type IReducer<S = any> = (state: S, action: IAnyAction) => S;

export interface IModelType<S, K extends keyof any> {
  namespace: keyof IConnectState;
  state: S;
  reducers?: {
    [P in K]?: IReducer<S>;
  };
  effects?: {
    [P in K]?: Effect;
  };
  subscriptions?: SubscriptionsMapObject;
}

/* ---------- model 类型定义 ---------- */

export const connectModel = getConnectModel(connectAction);
