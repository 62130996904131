// @ts-nocheck
import React from 'react';
import { ApplyPluginsType, dynamic } from '/workspace/node_modules/@umijs/runtime';
import * as umiExports from './umiExports';
import { plugin } from './plugin';
import LoadingComponent from '@/components/loading/index.tsx';

export function getRoutes() {
  const routes = [
  {
    "path": "/",
    "component": dynamic({ loader: () => import(/* webpackChunkName: 'layouts' */'@/layouts'), loading: LoadingComponent}),
    "routes": [
      {
        "path": "/",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__index' */'@/pages/index'), loading: LoadingComponent}),
        "wrappers": [dynamic({ loader: () => import(/* webpackChunkName: 'wrappers' */'@/components/permission'), loading: LoadingComponent})],
        "showLoading": true,
        "exact": true
      },
      {
        "path": "/home",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Home' */'@/pages/Home'), loading: LoadingComponent}),
        "wrappers": [dynamic({ loader: () => import(/* webpackChunkName: 'wrappers' */'@/components/permission'), loading: LoadingComponent})],
        "showLoading": true,
        "exact": true
      },
      {
        "path": "/policy",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Policy__List' */'@/pages/Policy/List'), loading: LoadingComponent}),
        "wrappers": [dynamic({ loader: () => import(/* webpackChunkName: 'wrappers' */'@/components/permission'), loading: LoadingComponent})],
        "showLoading": true,
        "exact": true
      },
      {
        "path": "/order/policy-list",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Policy__DetailList' */'@/pages/Policy/DetailList'), loading: LoadingComponent}),
        "wrappers": [dynamic({ loader: () => import(/* webpackChunkName: 'wrappers' */'@/components/permission'), loading: LoadingComponent})],
        "showLoading": true,
        "exact": true
      },
      {
        "path": "/policy/info",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Policy__Info' */'@/pages/Policy/Info'), loading: LoadingComponent}),
        "wrappers": [dynamic({ loader: () => import(/* webpackChunkName: 'wrappers' */'@/components/permission'), loading: LoadingComponent})],
        "showLoading": true,
        "exact": true
      },
      {
        "path": "/insurance/profile",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Profile' */'@/pages/Profile'), loading: LoadingComponent}),
        "wrappers": [dynamic({ loader: () => import(/* webpackChunkName: 'wrappers' */'@/components/permission'), loading: LoadingComponent})],
        "showLoading": true,
        "exact": true
      },
      {
        "path": "/product-protection/profile",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Profile' */'@/pages/Profile'), loading: LoadingComponent}),
        "wrappers": [dynamic({ loader: () => import(/* webpackChunkName: 'wrappers' */'@/components/permission'), loading: LoadingComponent})],
        "showLoading": true,
        "exact": true
      },
      {
        "path": "/insurance/web",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Profile__web' */'@/pages/Profile/web'), loading: LoadingComponent}),
        "wrappers": [],
        "showLoading": true,
        "exact": true
      },
      {
        "path": "/product-protection/web",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Profile__web' */'@/pages/Profile/web'), loading: LoadingComponent}),
        "wrappers": [],
        "showLoading": true,
        "exact": true
      },
      {
        "path": "/insurance/rn",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Profile__rn' */'@/pages/Profile/rn'), loading: LoadingComponent}),
        "exact": true
      },
      {
        "path": "/product-protection/rn",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Profile__rn' */'@/pages/Profile/rn'), loading: LoadingComponent}),
        "exact": true
      },
      {
        "path": "/test",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Test' */'@/pages/Test'), loading: LoadingComponent}),
        "wrappers": [],
        "exact": true
      },
      {
        "path": "/exception",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Exception' */'@/pages/Exception'), loading: LoadingComponent}),
        "exact": true
      },
      {
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__404' */'@/pages/404'), loading: LoadingComponent}),
        "exact": true
      }
    ]
  }
];

  // allow user to extend routes
  plugin.applyPlugins({
    key: 'patchRoutes',
    type: ApplyPluginsType.event,
    args: { routes },
  });

  return routes;
}
