/* eslint-disable prettier/prettier */
/* eslint-disable no-useless-escape */
/* eslint-disable @typescript-eslint/no-shadow */
import { loading, Toast } from '@credit/tips';
import { captureError } from './exceptionHandler';
import {
  getAppVersion,
  getRnVersion,
  isAndroid,
  isIOS,
  isPC,
  isSamePath,
  queryParams,
  queryStringify,
  sleep,
  stringNumber,
  switchKeys,
  toCamelCase,
  toSnakeCase,
  typeCheck,
} from '@credit/utils';
import { getDvaApp, history } from 'umi';
import { formatMessage as t, locale, ShopeeSupportLangsMap } from '@/utils/locale';
import en from '@/locales/en';
import appInfo from '@/stores/appInfo';
import { IConnectState, IUserInfo } from '@/types';
import bridge from './bridge';
// import Currency from './currency';
import Time from './time';
import request from './request';
import { getCookie } from './storage';
import { LoginToken } from '@/types/constant';

export {
  queryParams,
  queryStringify,
  isPC,
  isIOS,
  sleep,
  isAndroid,
  getAppVersion,
  getRnVersion,
  isSamePath,
  switchKeys,
  toCamelCase,
  toSnakeCase,
};

export const getDueDate = (timestamp: number | Date, showTime = false) => {
  const dateStr = 'DD ET YYYY';
  const timeStr = `${dateStr} hh:mm`;
  // console.log(Time.format(timestamp, showTime ? timeStr : dateStr));// 形如‘24 May 2016_expired’
  return Time.format(timestamp, showTime ? timeStr : dateStr);
};
export const getStringDate = (times: string | Date, showTime = false) => {
  const timestamp = new Date(times).getTime();
  return getDueDate(timestamp, showTime);
};

export const formatDate = Time.format;

// 跳转到home页
export const goHome = () => {
  history.push('/home');
};

// 关闭webview
export const closeWebview = () => {
  bridge.call('popWebView', {});
};

export const goEntry = () => {
  closeWebview();
  // if (appInfo.from === 'financial_circle') {
  //   goBack()
  //   // history.goBack();
  // } else {
  //   closeWebview();
  // }
};

export const appDispatch = (action: any) => {
  return getDvaApp()?._store?.dispatch?.(action);
};

// 默认跳转，后退到上一页面
export const goBack = () => {
  history.goBack();
  appDispatch({ type: 'general/onBacking' });
};

export const getAppState = <T extends keyof IConnectState>(name: T): IConnectState[T] => {
  return getDvaApp()?._store?.getState()?.[name];
};

export const getCurPathInfo = (() => {
  // eslint-disable-next-line no-void
  let routes: any = void 0;
  const routeInfoMap: any = {};
  return (pathname: string) => {
    if (routeInfoMap[pathname]) {
      return routeInfoMap[pathname];
    }
    if (!routes && typeCheck.isFunc(getDvaApp)) {
      routes = getDvaApp?.()._router()?.props?.routes;
    }
    const pathInfo: any = routes?.[0]?.routes?.find?.((item: any) =>
      isSamePath(item.path, pathname),
    );
    routeInfoMap[pathname] = pathInfo;
    return pathInfo;
  };
})();

export const showFullLoading = loading.showFullLoading;

export const hideFullLoading = loading.hideFullLoading;

export const setFullLoadingOnly = loading.setFullLoadingOnly;

export const hideAllFullLoading = loading.hideAllFullLoading;

const genLoginUrl = (redirectUrl: string) => {
  let env = REACT_APP_ENV;
  env = ['uat', 'live'].indexOf(env) >= 0 ? env : 'test';

  // 自测发现，加入_ts参数可以少一次重定向，速度提高很多
  // let ts = `${Date.now()}`;
  // ts = ts.substr(0, ts.length - 3);
  return `https://${env !== 'live' ? `${env}.` : ''
    }${BRIDGE_HOST}/authenticate/oauth?client_id=5&redirect_uri=${redirectUrl}&__mobile__=1&version=2`;
};

export const goWsaUrl = () => {
  let url = window.location.href;
  if (url.indexOf('?') > -1) {
    url = url.substring(0, url.indexOf('?'));
  }
  const offlineQuery = window._offline_build_url_query;
  // 兼容离线包
  if (offlineQuery) {
    url = `${url}index.html?${offlineQuery}`;
  }
  // 回跳的url形如 'https://cashloan.test.shopeekredit.co.id/activate?code=xxx&&errcode=0&errmsg=OK';
  const loginUrl = genLoginUrl(encodeURIComponent(url));
  // console.log(loginUrl);
  window.location.href = loginUrl;
  // bridge.callWebview(loginUrl)
};

// 延迟
const delay = (time: number) => new Promise((resolve) => setTimeout(resolve, time));

/**
 * 尝试获取authCode，按time进行重试的次数。
 * @param time
 */
const getAuthCodeByRetryTimes = async (time: number) => {
  let retryTime: number = time <= 0 ? 1 : time;

  while (retryTime > 0) {
    --retryTime;
    // eslint-disable-next-line no-await-in-loop
    const authCode = await bridge.getAuthCode('shopee-insurance');
    if (authCode) {
      return authCode;
    }

    // 加200毫秒的延迟
    // eslint-disable-next-line no-await-in-loop
    await delay(200);
  }

  return '';
};

export async function getAuthCode(): Promise<[number, string]> {
  const urlParams = queryParams();
  let authCode: string = urlParams.code;
  if (appInfo.authCode === authCode) {
    // url带了authcode参数，只能用一次。
    authCode = '';
  }

  if (authCode) {
    appInfo.authCode = authCode;
    return [0, authCode];
  }

  const errcode: string = urlParams.errcode;
  if (errcode && errcode !== '0') {
    // get wsa code fail。1告诉调用方，不用再重试，避免死循环
    bridge.trackPerformanceEvent('getAuthCodeFail', {
      fail_type: 1,
      err_code: errcode,
    });
    return [1, ''];
  }

  // mock环境对authcode无要求，dev环境在PC模拟器上也没有要求
  if (REACT_APP_ENV === 'mock') {
    return [0, 'mock'];
  }

  // 前两步处理从wsa网站重定向回来；下面的代码处理一进来的情况
  authCode = await getAuthCodeByRetryTimes(3);

  if (!authCode) {
    captureError({
      message: 'BRIDGE_AUTHCODE_ERR',
      errorInfo: {
        extra: {
          url: window.location.href,
          user: getDvaApp()._store.getState()?.userInfo ?? {},
        },
      },
    });
  }

  return [0, authCode]; // 如果为''，0告诉调用方，要用wsa url兜底
}

export const getLastPageType = () => {
  const store = getDvaApp()._store;
  const general = store.getState().general;
  return general.lastPageType;
};

/**
 * map all keys to values
 *   e.g. {a: 1, b: null} => {a: "a", b: "b"}
 * @param obj keys source
 */
export function keyMirror<T extends object, K extends keyof T>(obj: T): Record<K, string> {
  return Object.keys(obj).reduce((memo, key) => {
    memo[key as K] = key;
    return memo;
  }, {} as Record<K, string>);
}

/**
 * For better Vscode intellisense when typing umi local
 * formatMessage id
 */
export const CopyWriteKeyMap = keyMirror(en);

export const StrNum = stringNumber;

export const getMoney = (value: any) => {
  return {
    currency_code: MONEY_CODE,
    value,
  };
};

export const throttle = function (fn: any, delay: number = 500) {
  if (typeof fn !== 'function') {
    return fn;
  }

  // 利用闭包保存时间
  let prev = Date.now();
  return function () {
    // const context = this;
    const arg = arguments;
    const now = Date.now();
    if (now - prev >= delay) {
      // eslint-disable-next-line prefer-spread
      fn.apply(undefined, arg);
      prev = Date.now();
    }
  };
};

export const toErrPage = (status: string = 'busy') => {
  return history.push({ pathname: '/exception', state: { status } });
};
export const toHomePage = () => {
  return history.push({ pathname: '/' });
};

// 图片下载api
export const saveImg = (downloadUrl: string, fileName: string) => {
  if (isAndroid()) {
    // 安卓 saveImage API无法携带cookie, 没有修复,改成downloadFile API
    // downloadFile 文档: https://confluence.shopee.io/pages/viewpage.action?pageId=31438459
    return bridge.call('downloadFile' as any, {
      url: downloadUrl,
      fileName,
    });
  }
  // IOS 使用bridge saveImage 正常 注意saveImage自带Toast
  return bridge.call('saveImage', {
    imageUrl: downloadUrl,
    filename: fileName,
  });
};

export const isSupportSticky = () => {
  return CSS.supports('position', 'sticky');
};

// 多图片下载
export const saveImgs = async (downloadUrls: string[]) => {
  // 并行下载
  const pms = downloadUrls.map((imageUrl, i) => {
    const fileName: any = imageUrl.split('/') ? imageUrl.split('/').pop() : '';
    return saveImg(imageUrl, i + fileName);
  });
  const res = await Promise.all(pms);
  // 安卓自己写Toast
  if (isAndroid()) {
    if (res.some((x) => x.error === 0)) {
      // Toast.success(t({ id: 'download_success' }));
    } else {
      // Toast.fail(t({ id: 'download_fail' }));
    }
  }
};

// // 对正负数字向下取整
// export const getNumberCeil = (num: number) => {
//   if (isNaN(num)) {
//     return num
//   } else if (num < 0) {
//     return Math.ceil(num)
//   } else {
//     return Math.floor(num)
//   }
// }

export function goToMpSearch(searchParams: {
  minPrice: number;
  maxPrice: number;
  condition: string | boolean;
  facet: number[];
}) {
  const newSearchPar: any = {
    facet: searchParams?.facet ?? [],
  };
  if (searchParams.minPrice) {
    newSearchPar.minPrice = searchParams.minPrice;
  }
  if (searchParams.maxPrice) {
    newSearchPar.maxPrice = searchParams.maxPrice;
  }
  switch (searchParams?.condition) {
    case 'new':
      newSearchPar.condition = true;
      break;
    case 'second_hand':
      newSearchPar.condition = false;
      break;
    default:
      console.log('searchParams no condition');
  }
  // console.log(JSON.stringify({
  //   // apprl: '@shopee-rn/seller-payment/WALLET',
  //   apprl: 'rn/@shopee-rn/search/SEARCH_RESULT',
  //   params: {
  //     searchParams: newSearchPar,
  //     searchPageType: 'INSURANCE',
  //   },
  // }));
  bridge.call('navigateAppRL', {
    // apprl: '@shopee-rn/seller-payment/WALLET',
    apprl: 'rn/@shopee-rn/search/SEARCH_RESULT',
    params: {
      searchParams: newSearchPar,
      searchPageType: 'INSURANCE',
    },
  });
}

// const urlMapID = {
//   'test': 'https://test.shopee.co.id/m/free-item-protection-test',
//   'uat': ' https://uat.shopee.co.id/m/proteksi-gadget-gratis',
//   'live': 'https://shopee.co.id/m/proteksi-gadget-gratis '
// }
export function goToMicroSiteLink(targetUrl?: string) {
  if (targetUrl) {
    // console.log(targetUrl);
    bridge.callWebview(targetUrl);
  } else {
    // MY和VN的MicroSiteLink跳转，或者当传入的microsite的url为空时，不做任何处理
    const nation = BIZ_COUNTRY || 'id';
    if (nation === 'my') {
      const urlMapMY = {
        test: 'https://uat.shopee.com.my/m/cracked-screen-protection-activation-steps',
        dev: 'https://test.shopee.com.my/m/testtc',
        live: 'https://shopee.com.my/m/cracked-screen-protection-activation-steps',
        uat: 'https://uat.shopee.com.my/m/cracked-screen-protection-activation-steps',
        mock: 'https://test.shopee.com.my/m/testtc',
        test1: 'https://test.shopee.com.my/m/testtc',
      };
      bridge.callWebview(urlMapMY[REACT_APP_ENV]);
    }
    // 如果没有提供url，在id环境不跳转
  }
}

export function getSafeAreaInsetValue() {
  const styles = getComputedStyle(document.documentElement);
  const get = (key: String) =>
    parseFloat(styles.getPropertyValue(`--safe-area-inset-${key}`)?.trim?.());
  return {
    top: get('top'),
    right: get('right'),
    bottom: get('bottom'),
    left: get('left'),
  };
}

export function getCdnConfig(productId: string) {
  const nation = BIZ_COUNTRY || 'id'; // ${REACT_APP_ENV}
  const env = REACT_APP_ENV !== 'mock' ? REACT_APP_ENV : 'test';
  // const configInAdmin = ['my'];
  // const url = configInAdmin.includes(nation) ? `https://cdn.insurance.shopee.com/test/my/microsite_config.json` : `https://cdn.insurance.shopee.com/${nation}/insurance-cdn/${env}/microsite_config.json`;
  const url = `https://cdn.insurance.shopee.com/${nation}/insurance-cdn/${env}/microsite_config.json`
  return fetch(url, {
    method: 'GET',
    cache: 'no-cache',
  }).then((val) => val.json());
}

export function getCdnJson(productId: string) {
  const nation = BIZ_COUNTRY || 'id'; // ${REACT_APP_ENV}
  const env = REACT_APP_ENV !== 'mock' ? REACT_APP_ENV : 'test';
  const lowerCaseLocale = locale.toLowerCase();
  const supportsLangs = ShopeeSupportLangsMap[nation];
  const localeLangs = (supportsLangs?.supportLangs ?? []).includes(lowerCaseLocale)
    ? lowerCaseLocale
    : supportsLangs.defaultLang;
  let url: string;
  // const url = `https://cdn.deo.scredit.io/${nation}/insurance-config/dev/en/h5_profile_${productId}.json`
  // https://cdn.insurance.shopee.com/test/vn/vi/h5_profile_1559772006986024192.json
  // https://cdn.insurance.shopee.com/my/insurance-cdn/live/en/h5_profile_1385277174484245062.json
  switch (nation) {
    case 'my':
      url = `https://cdn.insurance.shopee.com/${nation}/insurance-cdn/${env}/${localeLangs}/h5_profile_${productId}.json`;
      // url = `https://cdn.insurance.shopee.com/${nation}/insurance-cdn/dev/${lowerCaseLocale}/h5_profile_${productId}.json`;
      break;
    case 'vn':
      url = `https://cdn.insurance.shopee.com/${nation}/insurance-cdn/${env}/${localeLangs}/h5_profile_${productId}.json`;
      break;
    case 'ph':
      url = `https://cdn.insurance.shopee.com/${nation}/insurance-cdn/${env}/${localeLangs}/h5_profile_${productId}.json`;
      break;
    case 'id':
      url = `https://cdn.insurance.shopee.com/${nation}/insurance-cdn/${env}/${localeLangs}/h5_profile_${productId}.json`;
      break;
    case 'th':
      url = `https://cdn.insurance.shopee.com/${nation}/insurance-cdn/${env}/${localeLangs}/h5_profile_${productId}.json`;
      // url = `https://cdn.insurance.shopee.com/${nation}/insurance-cdn/${env}/th/h5_profile_${productId}.json`;
      break;
    default:
      url = `https://cdn.deo.scredit.io/${nation}/insurance-config/${env}/${localeLangs}/h5_profile_${productId}.json`;
  }
  return fetch(url, {
    method: 'GET',
    cache: 'no-cache',
  }).then((val) => val.json()).catch(() => {
    RNReport({
      loadTime: Date.now(),
    });
    redirectNewPDP();
  });
}

export const redirectNewPDP = () => {
  const INS_H5_DOMAIN = {
    ID: {
      live: 'https://insurance.shopee.co.id',
      uat: 'https://insurance.uat.shopee.co.id',
      test: 'https://insurance.test.shopee.co.id',
      dev: 'https://insurance-env3.test.shopee.co.id',
      test1: 'https://insurance-env1.test.shopee.co.id',
      test2: 'https://insurance.test.shopee.co.id',
      staging: 'https://insurance.staging.shopee.co.id',
    },
    MY: {
      live: 'https://protection.shopee.com.my',
      uat: 'https://protection.uat.shopee.com.my',
      test: 'https://protection.test.shopee.com.my',
      dev: 'https://protection-env3.test.shopee.com.my',
      test1: 'https://protection-env1.test.shopee.com.my',
      staging: 'https://protection.staging.shopee.com.my',
    },
    VN: {
      live: 'https://insurance.shopee.vn',
      uat: 'https://insurance.uat.shopee.vn',
      test: 'https://insurance.test.shopee.vn',
      dev: 'https://insurance-env3.test.shopee.vn',
      test1: 'https://insurance-env1.test.shopee.vn',
      staging: 'https://insurance.staging.shopee.vn',
    },
    PH: {
      live: 'https://protection.seainsureagency.com.ph',
      uat: 'https://protection.uat.seainsureagency.com.ph',
      test: 'https://protection.test.seainsureagency.com.ph',
      dev: 'https://protection-env3.test.seainsureagency.com.ph',
      test1: 'https://protection-env1.test.seainsureagency.com.ph',
      staging: 'https://protection.staging.seainsureagency.com.ph',
    },
    TH: {
      live: 'https://protection.seainsurebroker.co.th',
      uat: 'https://protection.uat.seainsurebroker.co.th',
      test: 'https://protection.test.shopee.co.th',
      dev: 'https://protection-env3.test.seainsurebroker.co.th',
      test1: 'https://protection-env1.test.seainsurebroker.co.th',
      staging: 'https://protection.staging.seainsurebroker.co.th',
    },
    SG: {
      live: 'https://protection.shopee.sg',
      uat: 'https://protection.uat.shopee.sg',
      test: 'https://protection.test.shopee.sg',
      dev: 'https://protection.test.shopee.sg',
      test1: 'https://protection.test.shopee.sg',
      staging: 'https://protection.test.shopee.sg',
    },
  } as Record<string, any>
  const country = BIZ_COUNTRY || 'id';
  const envArr = ['live', 'uat', 'test'];
  const env = envArr.indexOf(REACT_APP_ENV) !== -1 ? REACT_APP_ENV : 'test';
  const domain = INS_H5_DOMAIN[country.toUpperCase()][env.toLowerCase()];
  const { href, pathname } = location;
  const oldURL = href.split('?') || [];
  const url = `${domain}/ec/ec-pdp.html?${oldURL[1]}${pathname.endsWith('rn') ? '&terminal=2' : ''}`;
  location.replace(url);
}

// 下边的域名是admin配置前json
// const url = `https://cdn.deo.scredit.io/${nation}/insurance-config/${env}/${lowerCaseLocale}/${filename}.json`
// const url = `https://cdn.deo.scredit.io/id/insurance-config/${env}/${filename}.json`

// Retrieve banner-related JSON
export function getCdnNonProductJson() {
  const nation = BIZ_COUNTRY || 'id';
  const envArr = ['live', 'uat', 'test'];
  const env = envArr.indexOf(REACT_APP_ENV) !== -1 ? REACT_APP_ENV : 'test';
  const lowerCaseLocale = locale.toLowerCase();
  const supportsLangs = ShopeeSupportLangsMap[nation];
  const localeLangs = (supportsLangs?.supportLangs ?? []).includes(lowerCaseLocale)
    ? lowerCaseLocale
    : supportsLangs.defaultLang;
  const filename = `${nation}_bg_banners`;
  const url = `https://cdn.insurance.shopee.com/${env}/${nation}/${localeLangs}/${filename}.json`;
  return fetch(url, {
    method: 'GET',
    cache: 'no-cache',
  }).then((val) => val.json());
}

export const clearImageTag = function (str: string) {
  const clearReg = /\<\/*image\>/g;
  return str.replace(clearReg, '')
}

export const replaceImageTag = function (str: string): { text: string; type: 'string' | 'image' }[] {
  const replaceReg = /\<image\>(.|\n)*\<\/image\>/g;
  const clearReg = /\<\/*image\>/g;

  const linkArr = (str.match(replaceReg) || []).map((item) => {
    return item.replace(clearReg, '');
  });
  const replacedStr = str.replace(replaceReg, '__SPACE__');
  const replacedArr = replacedStr.split('__SPACE__');
  const result: ReturnType<typeof replaceImageTag> = [];

  replacedArr.forEach((item, index) => {
    result.push({ text: item, type: 'string' });
    if (linkArr[index]) {
      result.push({ text: linkArr[index], type: 'image' });
    }
  });
  return result;
}

export const replaceLinkTag = function (str: string): { text: string; type: 'string' | 'link', targetUrl?: string }[] {
  const replaceReg = /\<link.*?\>(.|\n)*?\<\/link\>/g;
  const clearReg = /\<\/*link.*?\>/g;
  const extractTargetUrlReg = /src=['|"]+(.*?)['|"]+/
  const targetUrls = []
  // 假如有多个的情况targetUrl的情况
  /**
   * 注意回归下之前的link版本
   * 1. Visit <link>https://gg-shopeeph.axinan.com/login?redirectURL=%2F</link>.
   * 2. Truy cập trang web <link src="https://shopee.c.fuse.com.vn">Fuse Website</link>
   */

  const linkArr = (str.match(replaceReg) || []).map((item) => {
    targetUrls.push(item.match(extractTargetUrlReg) ? item.match(extractTargetUrlReg)?.[1] || '' : item.replace(clearReg, ''))
    return item.replace(clearReg, '');
  });
  const replacedStr = str.replace(replaceReg, '__SPACE__');
  const replacedArr = replacedStr.split('__SPACE__');
  const result: ReturnType<typeof replaceLinkTag> = [];

  replacedArr.forEach((item, index) => {
    result.push({ text: item, type: 'string' });
    if (linkArr[index]) {
      result.push({ text: linkArr[index], type: 'link', targetUrl: targetUrls[index] });
    }
  });
  return result;
};

export function checkIsLogin(): boolean {
  const userInfo = getAppState('userInfo');
  const { islogin }: IUserInfo = userInfo;
  if (islogin) {
    return true;
  }
  return false;
}

export function goToPHcCackedscreenMicrosite() {
  const envMap: Record<string, string> = {
    test: 'https://test.shopee.ph/events3/code/2325232004/',
    uat: 'https://uat.shopee.ph/events3/code/4117277629/',
    live: 'https://shopee.ph/m/cracked-screen-protection-activation-steps',
  };

  goToMicroSiteLink(envMap[REACT_APP_ENV]);
}

// 跳转到ph 保险公司的官网
export function goToPHCLWebsite() {
  const envMap: Record<string, string> = {
    test: 'https://www.test.seainsure.com.ph',
    uat: 'https://www.uat.seainsure.com.ph',
    live: 'https://www.seainsure.com.ph',
  };
  bridge.callWebview(envMap[REACT_APP_ENV]);
}

export const isPhCreditLife = (productId: string): boolean => {
  return productId === '1580431331886629461' && BIZ_COUNTRY === 'ph';
};

export const isIDCreditLifeNew = (productId: string): boolean => {
  return productId === '1497507862685941766' && BIZ_COUNTRY === 'id';
};
export const isCreditLife = (productId: string) => {
  const clProduct = ['1580431331886629461', '1497507862685941766', '1497507862685941760', '1385277174484242433']
  return clProduct.includes(productId)
}

export const replaceBoldTag = function (
  str: string,
): { text: string; type: 'string' | 'boldString' }[] {
  const replaceReg = /\<bold\>.*?\<\/bold\>/g;
  const clearReg = /\<\/*bold\>/g;
  const boldArr = (str.match(replaceReg) || []).map((item) => {
    return item.replace(clearReg, '');
  });
  const replacedStr = str.replace(replaceReg, '__SPACE__');
  const replacedArr = replacedStr.split('__SPACE__');
  const result: ReturnType<typeof replaceBoldTag> = [];

  replacedArr.forEach((item, index) => {
    result.push({ text: item, type: 'string' });
    if (boldArr[index]) {
      result.push({ text: boldArr[index], type: 'boldString' });
    }
  });

  return result;
};

export const mobileCheck = function () {
  let check = false;
  (function (a) {
    if (
      /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino/i.test(
        a,
      ) ||
      /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(
        a.substr(0, 4),
      )
    )
      check = true;
  })(navigator.userAgent || navigator.vendor || window.opera);
  return check;
};

export const isInShopeeApp = () => {
  let result = false;
  (function (ua) {
    if (/Shopee/i.test(ua)) {
      result = true;
    }
  })(navigator.userAgent || navigator.vendor || window.opera);
  return result;
};
export const isCallByDrawer = () => {
  return !!window?.ReactNativeWebView;
};

export const isCallByAdmin = () => {
  return appInfo.from === 'admin';
};

export const RNTracker = {
  call: (cmd: string, { events }: { events: string }) => {
    if (cmd !== 'trackBIEvent') {
      return;
    }
    try {
      const msg = {
        cmd: 'track',
        data: {
          ...JSON.parse(events)[0].info,
        },
      };
      window?.postMessage?.(JSON.stringify(msg));
    } catch (e) {
      console.log(e);
    }
  },
};

export const RNReport = (data: Record<string,number>)=>{
  try {
    const msg = {
      cmd: 'report',
      data: {
        ...data
      }
    }
    window?.postMessage?.(JSON.stringify(msg));
  } catch (error) {
    console.log(error);
  }
}

export const hyperLinkInterceptor = () => {
  document.body.addEventListener('click', function (event) {
    // 兼容处理
    const target = (event.target as Element) || (event.srcElement as Element);
    // 判断是否匹配目标元素
    if (target?.nodeName.toLocaleLowerCase() === 'a') {
      console.log('当前点击的 a 标签: ', target);

      // 对捕获到的 a 标签进行处理，需要先禁止它的跳转行为
      if (event.preventDefault) {
        event.preventDefault();
      } else {
        event.returnValue = true;
      }

      // 处理完 a 标签的内容，重新触发跳转，根据原来 a 标签页 target 来判断是否需要新窗口打开
      const url = target.getAttribute('href') ?? '#';
      bridge.callWebview(url);
      // bridge.cakkWebview自身做了处理，所以注释掉下边
      // if(!isInShopeeApp()){
      //   // 富文本编辑器生成的a标签，默认是_blank：<a href=\"https://shopee.co.th/\" rel=\"noopener noreferrer\" target=\"_blank\">https://shopee.co.th/</a>
      //   if (target.getAttribute("target") === '_blank') {
      //       window.open(url)
      //   } else {
      //       window.location.href = url
      //   }
      // }
      // else {
      //   bridge.callWebview(url)
      // }
    }
  });
};


export const getPhInsH5Domain = (): string=>{
  switch (REACT_APP_ENV) {
    case 'test': {
      return 'https://protection.test.seainsureagency.com.ph';
    }
    case 'uat': {
      return 'https://protection.uat.seainsureagency.com.ph';
    }
    case 'live': {
      return 'https://protection.seainsureagency.com.ph';
    }
    default: {
      return 'https://protection.seainsureagency.com.ph';
    }
  }
}

