import bridge from '@/utils/bridge';

const subConfig =
  REACT_APP_ENV === 'live'
    ? {
        appId: 'h5',
        secretKey: '67755748246f6e7a565a79467a244865',
        publicKey: '',
      }
    : {
        appId: 'h5',
        secretKey: '4564796a3d347652445739216f66472a',
        publicKey: '',
      };

const envConfig = (() => {
  // mock, dev, test1
  if (['mock', 'dev', 'test1'].indexOf(REACT_APP_ENV) >= 0) {
    const env = REACT_APP_ENV === 'test1' ? 'env1' : 'env3';
    return {
      iconPreUrl: `https://insurance-${env}.test.shopee.co.id`,
      bankSelectUrl: `https://test.shopee.co.id/bank-accounts/select?client=credit`,
      splHost: `https://h5-${env}.test.shopeekredit.co.id`,
      clHost: `https://cashloan-${env}.test.shopeekredit.co.id`,
      slHost: `https://sellerloan-${env}.test.shopeekredit.co.id`,
      riskHost: `https://risk-${env}.test.shopeekredit.co.id`,
      fastHost: `https://fast-escrow-${env}.test.shopeekredit.co.id`,
    };
  }

  // live
  if (REACT_APP_ENV === 'live') {
    return {
      // iconPreUrl: 'https://deo.shopeemobile.com/shopee/shopee-insurance-live-id',
      iconPreUrl: 'https://cdn.deo.scredit.io/id/insurance-config/live/',
      bankSelectUrl: 'https://shopee.co.id/bank-accounts/select?client=credit',
      splHost: 'https://h5.shopeekredit.co.id',
      clHost: 'https://cashloan.shopeekredit.co.id',
      riskHost: 'https://risk.shopeekredit.co.id',
      slHost: 'https://sellerloan.shopeekredit.co.id',
      fastHost: 'https://fastescrow-h5.shopeekredit.co.id',
    };
  }

  // test, uat
  return {
    iconPreUrl: `https://insurance.${REACT_APP_ENV}.shopeekredit.co.id`,
    bankSelectUrl: `https://${REACT_APP_ENV}.shopee.co.id/bank-accounts/select?client=credit`,
    splHost: `https://h5.${REACT_APP_ENV}.shopeekredit.co.id`,
    clHost: `https://cashloan.${REACT_APP_ENV}.shopeekredit.co.id`,
    slHost: `https://sellerloan.${REACT_APP_ENV}.shopeekredit.co.id`,
    riskHost: `https://risk.${REACT_APP_ENV}.shopeekredit.co.id`,
    fastHost: `https://fast-escrow.${REACT_APP_ENV}.shopeekredit.co.id`,
  };
})();

const config = {
  platForm: 1,
  version: 1,
  region: 'id',
  insuranceVersion: 'insurance_h5_v1.0.0.100',
  appVersion: bridge.checkAppVersion(),
  rnVersion: bridge.checkRnVersion(),
  ...subConfig,
  ...envConfig,
};

export default config;
