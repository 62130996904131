import ExceptionPlugin from '@mdap/sdk-plugin-exception';

// JS 异常信息监控插件
const exceptionHandler = new ExceptionPlugin();

export default exceptionHandler;

export const captureError = function (obj: {
  message: string;
  error?: Error;
  errorInfo?: {
    name?: string | undefined;
    data_field?: Record<string, string> | undefined;
    level?: 'error' | 'debug' | 'fatal' | 'info' | 'warn' | undefined;
    extra?: Record<string, unknown> | undefined;
  };
}) {
  console.log('>>>>>>>>> Capture Error', REACT_APP_ENV);
  if (REACT_APP_ENV === 'dev' || REACT_APP_ENV === 'mock') {
    return;
  }
  exceptionHandler.capture({
    message: obj.message,
    error: obj.error,
    data: {
      level: 'error',
      ...obj.errorInfo,
      data_field: {
        appVersion: RELEASE_TAG,
        ...obj.errorInfo?.data_field,
      },
    },
  });
};
