import { BIZ_TYPE } from './enums';

export const AndroidAppId = {
  SG: 'sg',
  MY: 'my',
  TH: 'th',
  VN: 'vn',
  PH: 'ph',
  TW: 'tw',
  ID: 'id',
  BR: 'br',
};

export const IOSAppId = {
  SG: '959840394',
  MY: '959841113',
  TH: '959841453',
  VN: '959841449',
  PH: '959841854',
  TW: '959841107',
  ID: '959841443',
  BR: '1481812175',
};

export const BizCommon = {
  biz_type: BIZ_TYPE.None,
  biz_country: BIZ_COUNTRY,
};

// getDay() 0为周日,从周日到周六
export const enWeekday = [
  'Sunday',
  'Monday',
  'Tuesday',
  'Wednesday',
  'Thursday',
  'Friday',
  'Saturday',
];
export const thWeekday = [
  'วันอาทิตย์',
  'วันจันทร์',
  'วันอังคาร',
  'วันพุธ',
  'วันพฤหัสบดี',
  'วันศุกร์',
  'วันเสาร์',
];

export const enMonth = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
];
export const thMonth = [
  'มกราคม',
  'กุมภาพันธ์',
  'มีนาคม',
  'เมษายน',
  'พฤษภาคม',
  'มิถุนายน',
  'กรกฎาคม',
  'สิงหาคม',
  'กันยายน',
  'ตุลาคม',
  'พฤศจิกายน',
  'ธันวาคม',
];

export const LoginToken = 'itoken';
