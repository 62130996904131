import MYZH from './my/zh-Hans.json';

export type ZhKeys = keyof typeof MYZH;

export type ZhType = {
  [k in ZhKeys]: string;
};

// eslint-disable-next-line import/no-dynamic-require
const zh: ZhType = require(`./${BIZ_COUNTRY}/zh-Hans.json`);

export default zh;
