import { IPageInfo } from '@/types';

export const policyNS = 'policy';

/**
 * https://confluence.shopee.io/display/SIN/%5BH5%5D+Query+Policies
 */
export enum PolicyStatusEnum {
  Unissued = 'unissued',
  Issued = 'issued',
  Expired = 'expired',
  Cancelled = 'cancelled',
  Inactive = 'inactive',
}
export const PolicyStatusConst: { [key: string]: string } = {
  [PolicyStatusEnum.Unissued]: 'not_yet_issued',
  [PolicyStatusEnum.Inactive]: 'to_activate',
  [PolicyStatusEnum.Issued]: 'active',
  [PolicyStatusEnum.Expired]: 'expired',
  [PolicyStatusEnum.Cancelled]: 'cancelled',
};

// export const PolicyStatusConst: { [key: string]: string } = {
//   [PolicyStatusEnum.Unissued]: 'Not Yet Issued',
//   [PolicyStatusEnum.Issued]: 'Issued',
//   [PolicyStatusEnum.Expired]: 'Expired',
//   [PolicyStatusEnum.Cancelled]: 'Cancelled',
//   [PolicyStatusEnum.Inactive]: 'To Active'
// }

// IMine
export interface IPolicy extends IPageInfo {
  list: any[];
  dataSource: any;
}

export enum PolicyAction {
  fetchInfo = 'fetchInfo',
  updateInfo = 'updateInfo',

  fetchList = 'fetchList',
  clearList = 'clearList',
  updateList = 'updateList',
}
export interface PolicyDetailItemInfo {
  checkout_time: string;
  pic_url: string;
  brand: string;
  model: string;
  account_id: string;
  partner_order_id: string;
  item_price: string;
  category_name: string;
  premium: string;
  item_name: string;
  order_id: string;
}

export interface InsuranceProduct {
  condition: string;
  min_item_price: string;
  max_item_price: string;
  categories: string[];
  period: string;
}

export interface GetPolicyDetailRsp {
  shopee_policy_no: string;
  policy_no: string;
  policy_status: PolicyStatusEnum;
  start_time: string;
  end_time: string;
  premium: string;
  product_detail_page_url: string;
  insurance_product_id: string;
  product_name: string;
  applicant_name: string;
  item_info: PolicyDetailItemInfo;
  insurance_product: InsuranceProduct;
  description: string;
  partner_order_id: string;
  pic_url: string;
  checkout_time: string;
  premium_before_discount: string;
}

export interface GetPolicyDetailListRsp {
  data: GetPolicyDetailRsp[];
}
