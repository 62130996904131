import { Time } from '@credit/utils';
import { Region } from '@credit/itypes/lib/types';
import { formatMessage as t } from './locale';

let region: Region = BIZ_COUNTRY;

switch (BIZ_COUNTRY) {
  case 'id':
    // id 部署在物理机，live环境在id， non live环境在sg
    region = REACT_APP_ENV === 'live' ? BIZ_COUNTRY : 'sg';
    break;
  case 'th':
    // th 部署在k8s，所有环境都在th
    region = BIZ_COUNTRY;
    break;
  case 'vn':
    region = BIZ_COUNTRY;
    break;
  case 'my': // TODO: 待确认
    region = BIZ_COUNTRY;
    break;
  case 'ph': // TODO: 待确认
    region = BIZ_COUNTRY;
    break;
  default:
    console.error('请设置region逻辑');
    break;
}

Time.config({
  region,
  accuracy: 1, // ms
  months: [
    t({ id: 'month1' }),
    t({ id: 'month2' }),
    t({ id: 'month3' }),
    t({ id: 'month4' }),
    t({ id: 'month5' }),
    t({ id: 'month6' }),
    t({ id: 'month7' }),
    t({ id: 'month8' }),
    t({ id: 'month9' }),
    t({ id: 'month10' }),
    t({ id: 'month11' }),
    t({ id: 'month12' }),
  ],
});

export default Time;
